// header scroll
var ct = 0;
$(window).scroll(function() {

  $('header').each(function() {
    var hh = $('header').height();

    var top = $(window).scrollTop(),
        h = $(window).height(),
        wb = $('body').find('.wa-box'),
        mr = $('body').find('.menu-right'),
        $chome = $('body').find('.hh-wrap'),
        header = $('header');

    if (top > hh) {
        header.addClass('fixed');
        wb.addClass('ishowed');
        mr.addClass('slide-h');
    } else {
        header.removeClass('fixed')
        wb.removeClass('ishowed');
        mr.removeClass('slide-h');
        // wb.removeClass('showed');
    }
    if (ct > top) {
        header.addClass('show');
    } else {
        header.removeClass('show');
    }

    if(top > h/2){
         jQuery('.back-to-top').addClass('h');

      } else {
        jQuery('.back-to-top').removeClass('h');
    }

    ct = top;

  });
});

var $chome = $('body').find('.hh-wrap'),
        wb = $('body').find('.wa-box');
  if ($chome.length == 0) {
    wb.addClass('showed');
  };

$('.icon-menu').click(function(){
  $('body').toggleClass('menu-open');
});
$('.menu-open:after').click(function(){
  $('body').toggleClass('menu-open');
});


$('.has-sub').click(function(){
    var t = $(this);
  if ($(window).width() < 1200) {
    t.toggleClass('sub-open');
    t.siblings().removeClass('sub-open');
  }
});

//BACK TO TOP
$('.to-top a').on('click', function() {
$("html, body").animate({
  scrollTop: 0
}, 1100);
});

//TOGGLE MENU
  $('.footer-menu').each(function() {
    var t = $(this),
        it = t.find('.item')
        ft = t.find('h4'),
        ul =  t.find('ul');
    it.click(function(){
      $(this).toggleClass('open');
    })
  });

//Masthead Slider
$('.slider-mh').each(function () {
  var t = $(this),
      totalItem = t.find('.item').length;
      if (totalItem > 1) {
        t.addClass('owl-carousel');
        t.owlCarousel({
          loop:true,
          nav: false,
          navText: ["<i class='fal fa-chevron-left'></i>","<i class='fal fa-chevron-right'></i>"],
          dots: true,
          margin: 10,
          items: 1,
          center: true,
          autoplay: true,
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
          autoplaySpeed: 800
        });
      } else {
        t.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
        t.find('.owl-stage-outer').children().unwrap();
    }
});





// //SLIDER EXTRA SMALL
// function sliderP() {
//   var sliderS = $('.slider-p');
//     sliderS.addClass('owl-carousel');
//     sliderS.owlCarousel({
//       navText: ["<i class='fal fa-chevron-left'></i>","<i class='fal fa-chevron-right'></i>"],
//       items: 3,
//       loop: true,
//       rewindNav: false,
//       nav: true,
//       dots: false,
//       center: true,
//       autoplay: false,
//       responsive : {
//         0 : {
//           items: 1
//         },
//         480 : {
//           items: 3
//         }
//       }
//     });
// }sliderP();

//SLIDER Product Home
function sliderP() {
  var sliderS = $('.slider-p'),
      $owl  = $('.slider-p');
    sliderS.addClass('owl-carousel');
    sliderS.owlCarousel({
      navText: ["<i class='fal fa-chevron-left'></i>","<i class='fal fa-chevron-right'></i>"],
      items: 1,
      loop: true,
      margin: 10,
      nav: true,
      dots: false,
      center: true,
      autoplay: false,
      smartSpeed: 800,
      // autoWidth: true,
      responsive : {
        0 : {
          items: 1
        },
        480 : {
          items: 3
        }
      }
    });
    sliderS.find('.item').each( function( index ) {
      $(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
    });
    $(document).on('click', '.owl-item>.item', function() {
      sliderS.trigger('to.owl.carousel', $(this).data( 'position' ) );
    });
}sliderP();

  // $owl  = $('.slider-p');
  // $owl.find('.item').each( function( index ) {
  //   $(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
  // });
  // $(document).on('click', '.owl-item>.item', function() {
  //   $owl.trigger('to.owl.carousel', $(this).data( 'position' ) );
  // });


//SLIDER FULL
$('.full-slider').each(function () {
  var t = $(this);
  t.addClass('owl-carousel');
  t.owlCarousel({
    navText: ["<i class='fal fa-chevron-left'></i>","<i class='fal fa-chevron-right'></i>"],
    loop:true,
    nav: true,
    dots: false,
    margin: 10,
    autoWidth: true,
    // stagePadding: 90,
    items: 1,
    center: true
  });
});


//Bg Auto
$('.bg-auto').each(function () {
  var t = $(this);
  t.addClass('owl-carousel');
  t.owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    // stagePadding: 90,
    items: 1,
    center: true,
    slideTransition: 400,
    smartSpeed: 250,
    autoplay: true,
    autoplaySpeed: 500,
    // autoHeight: true,
    mouseDrag: false
  });
});

$('.map-box').each(function(){
  var t = $(this),
      // msk = t.find('.mask'),
      actv = t.find('.active');
  $('.map').on('click', function(){
      $('.map').siblings().removeClass('active')
      $('.map').addClass('slide');
      setTimeout(function() {
          $('.map').removeClass('slide');
          $('.map').addClass('active');
      },1000);
  })
  $('.box-panorama').on('click', function(){
      $('.box-panorama').siblings().removeClass('active')
      $('.box-panorama').addClass('slide');
      setTimeout(function() {
          $('.box-panorama').removeClass('slide');
          $('.box-panorama').addClass('active');
      },1000);
  })
  // msk.on('click',function(){
  //     if(".box-panorama.active" != 0){
  //         $('.box-panorama').removeClass('active');
  //         $('.map').addClass('active');
  //     }else if(".map.active" != 0){
  //         $('.map').removeClass('active');
  //         $('.box-panorama').addClass('active');
  //         alert('cik')
  //     }else{
  //         alert('ck')
  //     }
  // })
  // msk.mouseover(function(){
  //     actv.addClass('hovered');
  // })
  // msk.mouseleave(function(){
  //     actv.removeClass('hovered');
  // })
})

function autoHeightSlider(){
  $('.div-full').each(function () {
    var t = $(this),
        ba = t.find('.bg-auto')
        os = t.find('.owl-stage'),
        item = t.find('.item');
    t.innerHeight();
    item.css('height', t.innerHeight());
    console.log(t.innerHeight());
  });
}autoHeightSlider();

$(document).keydown( function(eventObject) {
  if(eventObject.which==37) {//left arrow
  $('.owl-prev').click();//emulates click on prev button
  } else if(eventObject.which==39) {//right arrow
  $('.owl-next').click();//emulates click on next button
  }
} );

//PROJECT Slider
$('.projects-slider').each(function () {
  var t = $(this);
  t.addClass('owl-carousel');
  t.owlCarousel({
    loop:true,
    nav: true,
    navText: ["<i class='fal fa-chevron-left'></i>","<i class='fal fa-chevron-right'></i>"],
    dots: true,
    margin: 10,
    // stagePadding: 90,
    items: 1,
    center: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    autoplaySpeed: 800
  });
});

//SF Slider
$('.sf-slider').each(function () {
  var t = $(this);
  t.addClass('owl-carousel');
  t.owlCarousel({
    loop:true,
    nav: true,
    navText: ["<i class='fal fa-chevron-left'></i>","<i class='fal fa-chevron-right'></i>"],
    dots: false,
    margin: 10,
    // stagePadding: 90,
    items: 1,
    center: true,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1000
  });
});

//IMAGESLOADED
  $('body').css('position', 'fixed');
  $('body').imagesLoaded()
    .done(function() {
        $('#preloading').fadeOut('slow');
        $('body').css('position', 'relative');
    })
    .fail(function() {
        $('body').css('position', 'relative');
        $('#preloading').fadeOut('slow');
  });

// ANIMATE ON SCROLL
function animateScroll(){
    $('.animate').each(function(i){
        var bottom_of_object = $(this).offset().top + 10;
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        if( bottom_of_window > bottom_of_object ){
            $(this).addClass('animate-in');
        }
    });
}

function loadMore() {
    var size_li = $("#myList .col-sm-4").size(),
         l = 9,
         ad = 3,
         am = 2,
         lm = 2;
  if ($(window).width() < 767) {
      $(document).ready(function () {

        if (size_li <= lm) {
          $('#loadMore').hide();
        }else{
          $('#loadMore').show();
        };
        $('#myList .col-sm-4:lt('+lm+')').show();
        $('#loadMore').click(function () {
            var t = $(this);
            lm= (lm+3 <= size_li) ? lm+3 : size_li;
            $('#myList .col-sm-4:lt('+lm+')').show(function(){
              var hidden = $("#myList .col-sm-4:hidden").length;
              if(hidden == 0){
                t.hide();
              }
            });
        });
    });
  }else{
    $(document).ready(function () {

      if (size_li <= l) {
        $('#loadMore').hide();
      }else{
        $('#loadMore').show();
      };
        $('#myList .col-sm-4:lt('+l+')').show();
        $('#loadMore').click(function () {
            var t = $(this);
            l= (l+3 <= size_li) ? l+3 : size_li;
            $('#myList .col-sm-4:lt('+l+')').show(function(){
              var hidden = $("#myList .col-sm-4:hidden").length;
              if(hidden == 0){
                t.hide();
              }
            });
        });
    });
  }
}loadMore();

function setMH(){
  $('.home-press .card .title').matchHeight();
  $('.home-press .card .box-text').matchHeight();
  $('.press-list .card .title').matchHeight();
  $('.card-sport .box-desc').matchHeight();
  $('.ch-contact').each(function () {
  var t = $(this),
      ab = t.find('.address-box');
  ab.matchHeight();
  });

  $('.card-sport').each(function () {
    var t = $(this),
        bd = t.find('.box-desc');
    bd.matchHeight();
  });
}setMH();


//ON WINDOW RESIZE
$(window).resize(function() {
  setMH();
  autoHeightSlider();
});

$(window).load(function(){
  animateScroll();
  setMH();
  autoHeightSlider();
});

$(window).scroll(function(){
  animateScroll();
});

function headNav(){
  if ($(window).width() <= 1200) {
    $('.header-nav').each(function(){
      var t  = $(this),
          hs = t.find('.menu-item-has-children'),
          hsa = hs.children('a'),
          hsi = hs.find('.fas');
      console.log(hsi);
      hs.addClass('sub-closed');
      $('<i class="fas fa-caret-down"></i>').insertBefore(hsa);
      hs.click(function(){
        $(this).toggleClass('sub-open');
      });
    });
  };
}headNav();




// Select all links with hashes
$('.menu-right a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 20
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });

$('.menu-right').each(function(){
    var t = $(this)
        arrow = t.find('.arrow');
  arrow.on('click', function() {
    t.toggleClass('slide-h');
  });
});

// Select all links with hashes
$('.masthead-product a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 60
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });


// Menu-content

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var target = $(e.target).attr("href") // activated tab
    $('.sub-menu').find('li').removeClass('active');
    $(this).parent().addClass('active');
  });